<template>
    <BT-Blade-Item
        bladeName="release"
        :bladesData="bladesData"
        :canDelete="false"
        :loadingMsg="loadingMsg"
        navigation="releases"
        :refreshToggle="refreshToggle"
        title="Release">
        <template v-slot="{ item }">
            <v-row no-gutters>
                <v-col cols="12" sm="4" class="my-auto">
                    <BT-Field-Company
                        :id="item.courierID"
                        label="COURIER"
                        :title="item.courier.companyName" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Address
                        label="PICKUP FROM"
                        :value="item.departureLocation" />
                </v-col>

                <v-col cols="6" sm="4" class="d-flex align-center">
                    <BT-Field-Address
                        v-if="!item.isPickup"
                        label="DELIVER TO"
                        :value="item.destinationLocation" />
                    <div v-else class="warning--text">**PICKING UP</div>
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        label="Due Departure On"
                        shortDateAndTime
                        v-model="item.dueDepartureOn" />
                </v-col>

                <v-col cols="6" sm="4">
                    <v-list-item dense>
                        <v-list-item-content>
                            <v-list-item-subtitle>Status</v-list-item-subtitle>
                            <v-list-item-title v-if="item.isFulfilled">Completed</v-list-item-title>
                            <v-list-item-title v-else-if="!item.isPickup && item.deliveredOn != null">Delivered</v-list-item-title>
                            <v-list-item-title v-else-if="item.releasedOn != null">{{ item.isPickup ? 'Picked Up' : 'Released' }}</v-list-item-title>
                            <v-list-item-title v-else-if="item.isPickup">Ready</v-list-item-title>
                            <v-list-item-title v-else>Pending</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>

                <v-col v-if="item.releasedOn == null" cols="12" sm="6">
                    <v-divider class="my-2" />
                    <BT-Btn
                        class="primary"
                        @click="release(item)"
                        block
                        height="75%"
                        icon="mdi-clipboard-arrow-right-outline"
                        label="Release" />
                </v-col>
                <v-col v-else cols="12" sm="6">
                    <v-divider class="my-2" />
                    <v-subheader>Proof Of Release</v-subheader>
                    <BT-Signature
                        class="ml-4 mb-1"
                        height="75px"
                        v-model="item.proofOfRelease" />
                    <span class="ml-4 text-caption">
                        <span>By</span>
                        <BT-Entity
                            navigation="public-users"
                            inline
                            :itemValue="item.releasedByUserID"
                            single
                            textFilter="toUserLine"
                            useLocalCache />
                        <span>at {{ item.releasedOn | toShortDateAndTime }}</span>
                    </span>
                </v-col>

                <v-col cols="12">
                    <BT-Table
                        :canRefresh="false"
                        class="mt-4"
                        :headers="[
                            { text: 'Packages', value: 'measurement', display: true, title: 1 },
                            { text: 'Packed', value: 'quantityPacked', textFilter: 'toDisplayNumber', subtitle: 1, prefix: 'Packed: ' },
                            { text: 'Released', value: 'quantityReleased', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Released: ' }]"
                        hideActions
                        :items="item.packagedItems">
                        <template v-slot:actions>
                            <BT-Snack v-model="msg" />
                            <BT-Packages-Movement-Dialog
                                v-if="item.releasedOn == null"
                                v-model="item.packagedItems"
                                icon-only
                                packed
                                released
                                small
                                @ok="packages => { savePackages(packages, item) }" />
                        </template>
                        <template v-slot:measurement="{ item }">
                            <BT-Entity 
                                navigation="measurements"
                                :itemValue="item.measurementID"
                                itemText="measurementName" />
                        </template>
                        <template v-slot:listItem="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <BT-Entity 
                                        navigation="measurements"
                                        :itemValue="item.measurementID"
                                        itemText="measurementName" />
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ item | toMeasurementLine }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Packed: {{ item.quantityPacked | toDisplayNumber }} | Released: {{ item.quantityReleased | toDisplayNumber }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </BT-Table>
                </v-col>
            </v-row>

            <!-- <v-expansion-panels v-model="panelV">
                <BT-Expansion-Panel-List
                    :items="item.packagedItems"
                    label="Packages">
                    <template v-slot="{ item }">
                        <v-list-item-content>
                            <v-list-item-title>
                                <BT-Entity 
                                    navigation="measurements"
                                    :itemValue="item.measurementID"
                                    itemText="measurementName" />
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ item | toMeasurementLine }}</v-list-item-subtitle>
                            <v-list-item-subtitle>
                                Picked: {{ item.quantityPacked | toDisplayNumber }} Released: {{ item.quantityReleased | toDisplayNumber }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </BT-Expansion-Panel-List>
            </v-expansion-panels> -->

            
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';
import { measurementArea } from '~helpers';

export default {
    name: 'Receival-Blade',
    components: {
        BTPackagesMovementDialog: () => import('~components/BT-Packages-Movement-Dialog.vue'),
        BTSignature: () => import('~components/BT-Signature.vue'),
        BTTable: () => import('~components/BT-Table.vue')
    },
    data: function() {
        return {
            loadingMsg: null,
            msg: null,
            refreshToggle: false
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        async release(item) {
            try {
                this.loadingMsg = 'Releasing';
                await this.$BlitzIt.store.patch('releases', { id: item.id, rowVersion: item.rowVersion, releaseAsIs: true });
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async savePackages(packages, consignment) {
            try {
                this.loadingMsg = 'Saving Packages';

                consignment.packagedItems = packages;
                consignment.packagedItems.sort(firstBy(x => measurementArea(x)));

                await this.$BlitzIt.store.patch('releases', consignment);
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
    }
}
</script>